.contacts {
  padding: 80px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;

  &-title {
    font-size: 22px;
  }

  &-links {
    flex-direction: row;
    display: flex;
    @media (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &-button {
    margin: 20px !important;
    color: var(--text-color) !important;
    border-color: var(--text-color) !important;
    width: 210px;

    &-icon {
      margin-right: 10px;
    }
  }
}
