.group-project {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 100px;
  @media (max-width: 1200px) {
    flex-direction: column;
  }

  &-title {
    width: 15%;
    font-size: 22px;
    @media (max-width: 1200px) {
      margin-bottom: 30px;
    }
  }

  &-jobs {
    width: 85%;
  }

  &-job {
    margin-bottom: 50px;
    &-header {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
    }
    &-title {
      font-size: 32px;
      margin-bottom: 12px;
    }

    &-location {
      color: #6cace4;
      font-weight: 500;
    }

    &-date {
      font-weight: 300;
      @media (max-width: 1200px) {
        margin: 30px 0px;
      }
    }

    &-desc {
      line-height: 1.5;
      font-size: 18px;
      margin: 10px 0px;
    }
  }
}
