.nav-bar {
  width: 100%;
  height: 80px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }

  &-init {
    font-size: 28px;
    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }

  &-links {
    width: 400px;
    justify-content: space-between;
    font-size: 20px;
    display: flex;

    &-button {
      color: var(--text-color) !important;
    }
  }
}
