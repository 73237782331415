@import "./styles/navBar.scss";
@import "./styles/header.scss";
@import "./styles/experience.scss";
@import "./styles/projects.scss";
@import "./styles/contacts.scss";
@import "./styles/groupProjects.scss";

:root {
  // Default to light theme variables
  --background-color: #ffffff;
  --text-color: #333333;
  --button-bg-color: #007bff;
  --button-text-color: #ffffff;
  --header-bg-color: #f4f4f4;
  --header-text-color: #333333;
}

[data-theme="dark"] {
  // Dark theme variables
  --background-color: #111111;
  --text-color: #ffffff;
  --button-bg-color: #5865f2;
  --button-text-color: #ffffff;
  --header-bg-color: #222222;
  --header-text-color: #ffffff;
}

body {
  background-color: var(--background-color);
}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
  width: calc(100% - 10%);
  display: flex;
  padding: 0px 5%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    width: calc(100% - 20%);
    padding: 0px 10%;
  }
}
