.projects {
  width: 100%;
  border-bottom: 1px solid var(--text-color);
  padding: 80px 0px;
  &-title {
    width: 100%;
    display: flex;
    font-size: 22px;
    margin-bottom: 50px;
  }

  &-cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
      justify-content: center;
    }
  }

  &-card {
    height: 500px;
    width: 500px;
    padding: 20px;

    &-img {
      height: 300px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-bottom: 32px;

      &-1 {
        background-image: url("../assets/Img_1.png");
      }
      &-2 {
        background-image: url("../assets/Img_2.png");
      }
      &-3 {
        background-image: url("../assets/Img_3.png");
      }
      &-4 {
        background-image: url("../assets/Img_4.png");
      }
      &-5 {
        background-image: url("../assets/Img_5.png");
      }
      &-6 {
        background-image: url("../assets/Img_6.png");
      }
      @media (max-width: "600px") {
        height: 200px;
      }
    }

    &-title {
      font-size: 24px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-github {
      color: var(--text-color);
    }

    &-desc {
      margin: 20px 0px;
      font-weight: 300;
    }

    &-skills {
      color: #6cace4;
      font-weight: 500;
    }
  }
}
